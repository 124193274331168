<template>
  <div class="contracts-page">
    <h2>Contracts Templates</h2>
  </div>
</template>

<script>
export default {
  name: 'ContractsPage'
}
</script> 