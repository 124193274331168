<template>
  <div class="stock-page">
    <h2>Stock Management</h2>
  </div>
</template>

<script>
export default {
  name: 'StockPage'
}
</script> 