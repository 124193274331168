<template>
  <div class="proposals-page">
    <h2>Proposals Management</h2>
  </div>
</template>

<script>
export default {
  name: 'ProposalsPage'
}
</script> 