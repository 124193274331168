<template>
  <div class="project-history-page">
    <h2>Projects History</h2>
  </div>
</template>

<script>
export default {
  name: 'ProjectHistoryPage'
}
</script> 