<template>
  <div class="present-proposal">
    <!-- Header section -->
    <div class="header">
      <h2>Present Proposal:</h2><h3>{{ proposaladdress }}</h3>
      <div class="actions">
        <button class="btn primary" @click="saveProposal">
          <i class="fas fa-save"></i> Save as New Proposal
        </button>
        <button 
          class="btn" 
          @click="updateCurrentProposal"
          :disabled="!currentProposalId"
        >
          <i class="fas fa-sync"></i> Save Current Proposal
        </button>
        <button 
          class="btn delete" 
          @click="deleteCurrentProposal"
          :disabled="!currentProposalId"
        >
          <i class="fas fa-trash"></i> Delete Current Proposal
        </button>
      </div>
    </div>

    <!-- Main content area -->
    <div class="content">
      <!-- Proposal figure section -->
      <div class="proposal-figure">
        <div class="collapsible-sections">
          <!-- Installation Type Selection -->
          <div class="installation-type-container">
            <label for="installationType">Installation Type:</label>
            <select id="installationType" class="installation-select" v-model="selectedInstallationType">
              <option value="rooftop">Roof Top</option>
              <option value="ground">Ground Mount</option>
            </select>
          </div>
          
          <!-- Panel Size Selection -->
          <div class="panel-size-container">
            <label for="panelSize">Panel Size:</label>
            <select id="panelSize" class="panel-select" v-model="selectedPanelSize">
              <option value="405">405W</option>
              <option value="440">440W</option>
            </select>
          </div>
          
          <div class="collapsible-item">
            <button class="collapsible-btn" @click="toggleSection('program')" :class="{ active: activeSection === 'program' }">
              <i :class="['fas', activeSection === 'program' ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
              Program
            </button>
            <div class="collapsible-content" :class="{ show: activeSection === 'program' }">
              <div class="program-content">
                <select class="program-select" v-model="selectedProgram">
                  <option value="purchase">Purchase</option>
                  <option value="sunline">Sunline</option>
                  <option value="cghl">CGHL</option>
                </select>
                
                <!-- 当选择 Sunline 时显示年限选择 -->
                <div v-if="selectedProgram === 'sunline'" class="term-select-container">
                  <label for="termSelect">Payment Term (Years):</label>
                  <select id="termSelect" class="term-select" v-model="selectedTerm">
                    <option v-for="year in paymentTerms" :key="year" :value="year">
                      {{ year }} Years
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="collapsible-item">
            <button class="collapsible-btn" @click="toggleSection('hydro')" :class="{ active: activeSection === 'hydro' }">
              <i :class="['fas', activeSection === 'hydro' ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
              Hydro Bill
            </button>
            <div class="collapsible-content" :class="{ show: activeSection === 'hydro' }">
              <div class="hydro-content">
                <!-- 这里添加 Hydro Bill 相关内容 -->
                <table class="hydro-table">
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Usage (kWh)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(usage, index) in hydroUsage" :key="index">
                      <td>{{ usage.month }}</td>
                      <td>
                        <input 
                          type="number" 
                          v-model="usage.value"
                          class="usage-input"
                          style="width: 60px"
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- 在 saved-proposals div 前添加按钮 -->
          <div class="compare-section">
            <button 
              class="btn compare-btn" 
              @click="compareProposals"
              :disabled="!hasSelectedProposals"
            >
              <i class="fas fa-columns"></i> Compare Selected Proposals
            </button>
          </div>

          <!-- 添加保存的提案列表 -->
          <div class="saved-proposals">
            <h4>Saved Proposals</h4>
            <div 
              v-for="proposal in savedProposals" 
              :key="proposal.id"
              class="saved-proposal-item"
              :class="{ active: currentProposalId === proposal.id }"
            >
              <label class="checkbox-container">
                <input 
                  type="checkbox" 
                  v-model="proposal.selected"
                  @click.stop
                >
                <span class="checkmark"></span>
              </label>
              <span class="proposal-name" @click="loadProposal(proposal)">
                {{ proposal.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="proposal-design">
        <!-- 添加按钮 -->
        <button 
          class="add-panel-btn" 
          :disabled="!selectedArea"
          @click="addPanel"
        >
          <i class="fas fa-plus"></i>
        </button>

        <svg width="100%" height="100%" viewBox="0 0 800 600" preserveAspectRatio="xMidYMid meet">
          <!-- 上半部分红色矩形 -->
          <rect 
            x="50" 
            y="50" 
            width="700" 
            height="250" 
            :fill="redAreaHovered ? '#C85C5C' : '#B84C4C'"
            :stroke="selectedArea === 'red' ? '#2196F3' : 'none'"
            stroke-width="3"
            @mouseover="redAreaHovered = true"
            @mouseout="redAreaHovered = false"
            @click="selectArea('red')"
            class="clickable-area"
          />
          
          <!-- 下半部分黄色矩形 -->
          <rect 
            x="50" 
            y="300" 
            width="700" 
            height="250" 
            :fill="yellowAreaHovered ? '#FDC823' : '#FDB813'"
            :stroke="selectedArea === 'yellow' ? '#2196F3' : 'none'"
            stroke-width="3"
            @mouseover="yellowAreaHovered = true"
            @mouseout="yellowAreaHovered = false"
            @click="selectArea('yellow')"
            class="clickable-area"
          />
          
          <!-- 添加的面板 -->
          <rect
            v-for="panel in panels"
            :key="panel.id"
            :x="panel.x"
            :y="panel.y"
            :width="40"
            :height="60"
            fill="#333333"
            stroke="#666666"
            stroke-width="2"
            @mousedown="startDragging($event, panel)"
            class="draggable-panel"
          />

          <!-- 小方块及其阴影 -->
          <g transform="translate(600, 450)">
            <!-- 阴影效果 -->
            <defs>
              <!-- 左侧阴影 -->
              <radialGradient id="leftShadow" cx="1" cy="0.5" r="1">
                <stop offset="0%" stop-color="rgba(128, 0, 0, 0.5)" />
                <stop offset="40%" stop-color="rgba(128, 0, 0, 0.2)" />
                <stop offset="100%" stop-color="rgba(128, 0, 0, 0)" />
              </radialGradient>
              <!-- 右上阴影 -->
              <radialGradient id="topRightShadow" cx="0" cy="1" r="1">
                <stop offset="0%" stop-color="rgba(128, 0, 0, 0.5)" />
                <stop offset="40%" stop-color="rgba(128, 0, 0, 0.2)" />
                <stop offset="100%" stop-color="rgba(128, 0, 0, 0)" />
              </radialGradient>
              <!-- 左上阴影 -->
              <radialGradient id="topLeftShadow" cx="1" cy="1" r="1">
                <stop offset="0%" stop-color="rgba(128, 0, 0, 0.5)" />
                <stop offset="40%" stop-color="rgba(128, 0, 0, 0.2)" />
                <stop offset="100%" stop-color="rgba(128, 0, 0, 0)" />
              </radialGradient>
            </defs>
            <!-- 左侧阴影椭圆 -->
            <ellipse cx="-30" cy="25" rx="80" ry="60" fill="url(#leftShadow)" />
            <!-- 右上阴影椭圆 -->
            <ellipse cx="25" cy="-30" rx="60" ry="80" fill="url(#topRightShadow)" />
            <!-- 左上阴影椭圆 -->
            <ellipse cx="-20" cy="-20" rx="70" ry="70" fill="url(#topLeftShadow)" />
            <!-- 实际的小方块 -->
            <rect x="0" y="0" width="50" height="50" fill="#EEEEEE" stroke="#666666" />
          </g>
        </svg>
      </div>
      <div class="proposal-result">
        <div class="result-item">
          <label>Panel Size:</label>
          <span class="value">{{ selectedPanelSize }}W</span>
        </div>
        <div class="result-item">
          <label>Panel Qty:</label>
          <span class="value">{{ panels.length }}</span>
        </div>
        <div class="result-item">
          <label>Total System Size DC:</label>
          <span class="value">{{ totalSystemSize }}kW</span>
        </div>
        <div class="result-item">
          <label>Average Efficiency:</label>
          <span class="value">{{ calculateAverageEfficiency() }}</span>
        </div>
        <div class="result-item">
          <label>Estimated Solar Production:</label>
          <span class="value">{{ calculateSolarProduction() }}<span style="font-size: 0.8em">kWh/y</span></span>
        </div>
        <div class="result-item">
          <label>Annual Consumption:</label>
          <span class="value">{{ calculateAnnualConsumption() }}<span style="font-size: 0.8em">kWh/y</span></span>
        </div>
        <div class="chart-container">
          <div class="chart-title">Offset</div>
          <div class="chart-wrapper">
            <canvas ref="offsetChart"></canvas>
          </div>
        </div>
        <div class="result-item">
          <label>Unit price:</label>
          <span class="value">{{ unitPrice }}<span style="font-size: 0.8em">$/wattDC</span></span>
        </div>
        <div class="result-item">
          <label>Solar Power System Cost:</label>
          <span class="value">${{ calculateSystemCost() }}</span>
        </div>
        <div class="result-item">
          <label>HST (15%):</label>
          <span class="value">${{ calculateHST() }}</span>
        </div>
        <div class="result-item">
          <label>Total System Cost with HST:</label>
          <span class="value">${{ calculateTotalCostWithHST() }}</span>
        </div>
        <div class="result-item">
          <label>EPEI Rebate:</label>
          <span class="value">-${{ calculateEPEIRebate() }}</span>
        </div>
        <div class="result-item">
          <label>Cost After Rebate:</label>
          <span class="value">${{ calculateCostAfterRebate() }}</span>
        </div>
        <!-- 根据 Program 类型显示不同的付款信息 -->
        <template v-if="selectedProgram === 'purchase'">
          <div class="result-item permit-fee">
            <label>*Permit & Connection Fees:</label>
            <span class="value">$598.00</span>
          </div>
          <div class="result-item payment">
            <label>PMT 1 (Upon Proposal Sign):</label>
            <span class="value">$598.00</span>
          </div>
          <div class="result-item payment">
            <label>PMT 2 (15.0% + HST):</label>
            <span class="value">${{ calculatePMT2() }}</span>
          </div>
          <div class="result-item payment">
            <label>**PMT 3 (85.0% + HST):</label>
            <span class="value">${{ calculatePMT3() }}</span>
          </div>
        </template>

        <template v-else-if="selectedProgram === 'sunline'">
          <div class="result-item payment">
            <label>PMT 1 (Upon Sign):</label>
            <span class="value">${{ permitFee }}.00</span>
          </div>
          <div class="result-item payment">
            <label>PMT 2 (SAR Stage):</label>
            <span class="value">${{ calculateSunlineDownPayment() }}</span>
          </div>
          <div class="result-item payment">
            <label>PMT 3 (Connection Completed):</label>
            <span class="value">${{ calculateSunlineMonthlyPayment() }}/Month</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import Chart from 'chart.js/auto'

export default {
  name: 'PresentProposal',
  data() {
    return {
      proposaladdress: '6666 PEI SAR Street, Markham, Prince Edward Island, L3S4R4, Canada',
      activeSection: null,
      selectedProgram: 'purchase',
      selectedTerm: 10,
      paymentTerms: [10, 11, 12, 13, 14, 15],
      selectedPanelSize: '405',
      selectedInstallationType: 'rooftop',
      hydroUsage: [
          { month: 'Jan', value: 1205 },
          { month: 'Feb', value: 1205 },
          { month: 'Mar', value: 1087 },
          { month: 'Apr', value: 1087 },
          { month: 'May', value: 922 },
          { month: 'Jun', value: 922 },
          { month: 'Jul', value: 1004 },
          { month: 'Aug', value: 1004 },
          { month: 'Sep', value: 1065 },
          { month: 'Oct', value: 1065 },
          { month: 'Nov', value: 1256 },
          { month: 'Dec', value: 1256 }
      ],
      redAreaHovered: false,
      yellowAreaHovered: false,
      selectedArea: null,
      panels: [],
      nextPanelId: 1,
      isDragging: false,
      currentPanel: null,
      dragOffset: { x: 0, y: 0 },
      chart: null,
      chartUpdateTimeout: null,
      unitPrice: 2.99,
      hstRate: 0.15,  // 15% HST rate
      epeiRebateRate: 1,  // 10% rebate rate
      epeiRebateMax: 10000,  // Maximum rebate amount
      permitFee: 598.00,
      pmt2Percentage: 0.15,
      pmt3Percentage: 0.85,
      savedProposals: [],
      currentProposalId: null,
      proposalCounter: 1
    }
  },
  
  computed: {
    totalSystemSize() {
      return (this.panels.length * Number(this.selectedPanelSize) / 1000).toFixed(2)
    },
    hasSelectedProposals() {
      return this.savedProposals.filter(p => p.selected).length > 1
    }
  },
  
  methods: {
    toggleSection(section) {
      this.activeSection = this.activeSection === section ? null : section
    },
    
    selectArea(area) {
      this.selectedArea = this.selectedArea === area ? null : area
    },
    
    addPanel() {
      if (this.selectedArea) {
        const newPanel = {
          id: this.nextPanelId++,
          x: 100,
          y: this.selectedArea === 'red' ? 100 : 350,
          efficiency: this.selectedArea === 'red' ? 800 : 1100  // 初始效率
        }
        
        // 如果已经有面板，则在最后一个面板右侧添加新面板
        if (this.panels.length > 0) {
          const lastPanel = this.panels[this.panels.length - 1]
          newPanel.x = lastPanel.x + 50  // 在上一个面板右侧50像素处添加新面板
          
          // 如果超出边界，换行
          if (newPanel.x > 650) {  // 700(总宽) - 50(边距)
            newPanel.x = 100
            newPanel.y += 70  // 向下移动一行
          }
        }
        
        this.updatePanelEfficiency(newPanel)  // 更新效率
        this.panels.push(newPanel)
        this.updateChart()  // 添加面板后更新图表
      }
    },

    startDragging(event, panel) {
      this.isDragging = true
      this.currentPanel = panel
      
      // 计算鼠标点击位置与面板左上角的偏移
      const rect = event.target.getBoundingClientRect()
      const svgRect = event.target.ownerSVGElement.getBoundingClientRect()
      
      // 计算SVG的缩放比例
      const scaleX = event.target.ownerSVGElement.viewBox.baseVal.width / svgRect.width
      const scaleY = event.target.ownerSVGElement.viewBox.baseVal.height / svgRect.height
      
      this.dragOffset = {
        x: (event.clientX - rect.left) * scaleX,
        y: (event.clientY - rect.top) * scaleY
      }
      
      // 添加全局事件监听
      window.addEventListener('mousemove', this.handleDrag)
      window.addEventListener('mouseup', this.stopDragging)
    },
    
    handleDrag(event) {
      if (!this.isDragging || !this.currentPanel) return
      
      const svgRect = event.target.ownerSVGElement?.getBoundingClientRect()
      if (!svgRect) return

      // 计算SVG的缩放比例
      const scaleX = 800 / svgRect.width  // 800 是 SVG viewBox 的宽度
      const scaleY = 600 / svgRect.height // 600 是 SVG viewBox 的高度
      
      // 计算新位置
      let newX = (event.clientX - svgRect.left) * scaleX - this.dragOffset.x
      let newY = (event.clientY - svgRect.top) * scaleY - this.dragOffset.y
      
      // 限制在选中区域内
      const minX = 50  // 左边界
      const maxX = 710 // 右边界 (750 - 新的面板宽度)
      const minY = this.selectedArea === 'red' ? 50 : 300  // 上边界
      const maxY = this.selectedArea === 'red' ? 240 : 490 // 下边界 (考虑新的面板高度)
      
      // 应用边界限制
      newX = Math.max(minX, Math.min(maxX, newX))
      newY = Math.max(minY, Math.min(maxY, newY))
      
      // 更新面板位置
      this.currentPanel.x = newX
      this.currentPanel.y = newY
      
      // 更新效率
      this.updatePanelEfficiency(this.currentPanel)
    },
    
    stopDragging() {
      this.isDragging = false
      this.currentPanel = null
      
      // 在拖动结束时更新图表
      this.updateChart()
      
      // 移除全局事件监听
      window.removeEventListener('mousemove', this.handleDrag)
      window.removeEventListener('mouseup', this.stopDragging)
    },

    updatePanelEfficiency(panel) {
      // 检查面板是否在红色区域 (y < 300)
      if (panel.y < 300) {
        panel.efficiency = 800
        return
      }
      
      // 检查面板是否在阴影区域内
      if (this.isInShadowArea(panel)) {
        panel.efficiency = 900
      } else {
        panel.efficiency = 1100
      }
    },

    isInShadowArea(panel) {
      // 阴影区域中心点
      const shadowCenterX = 600
      const shadowCenterY = 450
      
      // 计算面板中心点
      const panelCenterX = panel.x + 20  // 面板宽度的一半
      const panelCenterY = panel.y + 30  // 面板高度的一半
      
      // 计算面板中心点到阴影中心的距离
      const distance = Math.sqrt(
        Math.pow(panelCenterX - shadowCenterX, 2) + 
        Math.pow(panelCenterY - shadowCenterY, 2)
      )
      
      // 如果距离小于100（阴影半径），认为在阴影区域内
      return distance < 100
    },

    calculateAverageEfficiency() {
      if (this.panels.length === 0) return 'N/A'
      
      const totalEfficiency = this.panels.reduce((sum, panel) => sum + panel.efficiency, 0)
      return Math.round(totalEfficiency / this.panels.length)
    },

    calculateSolarProduction() {
      if (this.panels.length === 0) return '0'
      
      const avgEfficiency = this.calculateAverageEfficiency()
      if (avgEfficiency === 'N/A') return '0'
      
      const production = Number(this.totalSystemSize) * avgEfficiency 
      return production.toFixed(0)
    },

    calculateAnnualConsumption() {
      const total = this.hydroUsage.reduce((sum, month) => sum + Number(month.value), 0)
      return total.toFixed(0)
    },
    
    calculateOffset() {
      const annualConsumption = Number(this.calculateAnnualConsumption())
      const solarProduction = Number(this.calculateSolarProduction())
      if (annualConsumption === 0 || solarProduction === 0) return '0.00'
      return ((solarProduction / annualConsumption) * 100).toFixed(2)
    },
    
    updateChart() {
      console.log('updateChart called')
      this.$nextTick(() => {
        console.log('updateChart called 01')
        const canvas = this.$refs.offsetChart
        if (!canvas) return
        
        const offset = Number(this.calculateOffset())
        const remaining = Math.max(0, 100 - offset)
        
        if (this.chart) {
          this.chart.destroy()
          this.chart = null
        }

        const config = {
          type: 'doughnut',
          data: {
            datasets: [{
              data: [offset || 0, remaining || 100],  // 确保即使offset为0也显示图表
              backgroundColor: ['#4CAF50', '#FDB813'],
              borderWidth: 0
            }]
          },
          options: {
            events: [],
            animation: false,
            responsive: true,
            maintainAspectRatio: true,
            cutout: '65%',
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: false
              }
            }
          }
        }

        try {
          const ctx = canvas.getContext('2d')
          this.chart = new Chart(ctx, config)
          
          // 调整中心文本大小
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          ctx.font = 'bold 16px Arial'
          ctx.fillStyle = '#333'
          ctx.fillText(
            (offset || 0).toFixed(1) + '%',
            canvas.width / 2,
            canvas.height / 2
          )
        } catch (error) {
          console.error('Error creating chart:', error)
        }
      })
    },
    beforeUnmount() {
      if (this.chart) {
        this.chart.destroy()
        this.chart = null
      }
    },
    calculateSystemCost() {
      const totalWatts = this.panels.length * Number(this.selectedPanelSize)
      const cost = totalWatts * this.unitPrice
      return cost.toLocaleString('en-US', { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      })
    },
    calculateHST() {
      const systemCost = this.panels.length * Number(this.selectedPanelSize) * this.unitPrice
      const hst = systemCost * this.hstRate
      return hst.toLocaleString('en-US', { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      })
    },
    calculateTotalCostWithHST() {
      const systemCost = this.panels.length * Number(this.selectedPanelSize) * this.unitPrice
      const totalCost = systemCost * (1 + this.hstRate)
      return totalCost.toLocaleString('en-US', { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      })
    },
    calculateEPEIRebate() {
      const systemDCSize = this.panels.length * Number(this.selectedPanelSize)
      const rebate = Math.min(
        systemDCSize * this.epeiRebateRate,
        this.epeiRebateMax
      )
      return rebate.toLocaleString('en-US', { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      })
    },
    calculateCostAfterRebate() {
      const totalCost = this.panels.length * Number(this.selectedPanelSize) * this.unitPrice * (1 + this.hstRate)
      const systemDCSize = this.panels.length * Number(this.selectedPanelSize)
      const rebate = Math.min(
        systemDCSize * this.epeiRebateRate,
        this.epeiRebateMax
      )
      const finalCost = totalCost - rebate
      return finalCost.toLocaleString('en-US', { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      })
    },
    calculatePMT2() {
      const baseAmount = this.panels.length * Number(this.selectedPanelSize) * this.unitPrice
      const payment = baseAmount * this.pmt2Percentage * (1 + this.hstRate)
      return payment.toLocaleString('en-US', { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      })
    },
    calculatePMT3() {
      const baseAmount = this.panels.length * Number(this.selectedPanelSize) * this.unitPrice
      const payment = baseAmount * this.pmt3Percentage * (1 + this.hstRate)
      return payment.toLocaleString('en-US', { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      })
    },
    calculateSunlineDownPayment() {
      // 这里可以根据实际需求调整计算逻辑
      return '1,669.80'
    },
    
    calculateSunlineMonthlyPayment() {
      const totalCost = this.panels.length * Number(this.selectedPanelSize) * this.unitPrice * (1 + this.hstRate)
      const rebate = Math.min(
        this.panels.length * Number(this.selectedPanelSize) * this.epeiRebateRate,
        this.epeiRebateMax
      )
      const finalCost = totalCost - rebate
      
      // 计算每月付款金额
      const monthlyPayment = finalCost / (Number(this.selectedTerm) * 12)
      
      return monthlyPayment.toLocaleString('en-US', { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      })
    },
    saveProposal() {
      const newProposal = {
        id: Date.now(),
        name: `Proposal V${this.proposalCounter}`,
        selected: false,  // 添加选中状态
        data: {
          selectedProgram: this.selectedProgram,
          selectedTerm: this.selectedTerm,
          selectedPanelSize: this.selectedPanelSize,
          selectedInstallationType: this.selectedInstallationType,
          hydroUsage: JSON.parse(JSON.stringify(this.hydroUsage)),
          panels: JSON.parse(JSON.stringify(this.panels)),
          nextPanelId: this.nextPanelId
        }
      }

      // 添加到保存的提案列表
      this.savedProposals.push(newProposal)
      this.proposalCounter++
      this.currentProposalId = newProposal.id

      // 可以选择保存到localStorage
      localStorage.setItem('savedProposals', JSON.stringify(this.savedProposals))
    },

    loadProposal(proposal) {
      // 加载选中的提案数据
      const data = proposal.data
      this.selectedProgram = data.selectedProgram
      this.selectedTerm = data.selectedTerm
      this.selectedPanelSize = data.selectedPanelSize
      this.selectedInstallationType = data.selectedInstallationType
      this.hydroUsage = JSON.parse(JSON.stringify(data.hydroUsage))
      this.panels = JSON.parse(JSON.stringify(data.panels))
      this.nextPanelId = data.nextPanelId
      this.currentProposalId = proposal.id

      // 更新图表
      this.updateChart()
    },

    updateCurrentProposal() {
      if (!this.currentProposalId) return
      
      // 找到当前提案的索引
      const index = this.savedProposals.findIndex(p => p.id === this.currentProposalId)
      if (index === -1) return
      
      // 更新提案数据
      const updatedProposal = {
        ...this.savedProposals[index],
        data: {
          selectedProgram: this.selectedProgram,
          selectedTerm: this.selectedTerm,
          selectedPanelSize: this.selectedPanelSize,
          selectedInstallationType: this.selectedInstallationType,
          hydroUsage: JSON.parse(JSON.stringify(this.hydroUsage)),
          panels: JSON.parse(JSON.stringify(this.panels)),
          nextPanelId: this.nextPanelId
        }
      }
      
      // 更新提案列表
      this.savedProposals.splice(index, 1, updatedProposal)
      
      // 更新 localStorage
      localStorage.setItem('savedProposals', JSON.stringify(this.savedProposals))
    },

    deleteCurrentProposal() {
      if (!this.currentProposalId) return
      
      // 找到当前提案的索引
      const index = this.savedProposals.findIndex(p => p.id === this.currentProposalId)
      if (index === -1) return
      
      // 从数组中移除提案
      this.savedProposals.splice(index, 1)
      
      // 更新 localStorage
      localStorage.setItem('savedProposals', JSON.stringify(this.savedProposals))
      
      // 清除当前选中的提案
      this.currentProposalId = null
      
      // 重置所有状态到默认值
      this.selectedProgram = 'purchase'
      this.selectedTerm = 10
      this.selectedPanelSize = '405'
      this.selectedInstallationType = 'rooftop'
      this.panels = []
      this.nextPanelId = 1
      
      // 更新图表
      this.updateChart()
    },
    compareProposals() {
      const selectedProposals = this.savedProposals.filter(p => p.selected)
      if (selectedProposals.length < 2) return
      
      // 将选中的提案数据存储到 localStorage
      localStorage.setItem('compareProposals', JSON.stringify(selectedProposals))
      
      // 打开新窗口进行比较
      window.open('/workplace/crm/compare-proposals', '_blank')
    }
  },
  mounted() {
    // 从localStorage加载保存的提案
    const savedProposals = localStorage.getItem('savedProposals')
    if (savedProposals) {
      this.savedProposals = JSON.parse(savedProposals)
      this.proposalCounter = this.savedProposals.length + 1
    }
    this.updateChart()
  },
  watch: {
    panels: {
      deep: true,
      handler() {
        this.updateChart()
      }
    },
    hydroUsage: {
      deep: true,
      handler() {
        this.updateChart()
      }
    }
  },
  
  setup() {
    const proposals = ref([])
    const selectedProposal = ref(null)
    const currentProposal = computed(() => {
      if (!selectedProposal.value) return null
      return proposals.value.find(p => p.id === selectedProposal.value)
    })

    const startPresentation = () => {
      console.log('Starting presentation')
    }

    const uploadProposal = () => {
      console.log('Upload proposal')
    }

    return {
      proposals,
      selectedProposal,
      currentProposal,
      startPresentation,
      uploadProposal
    }
  }
}
</script>

<style scoped>
.present-proposal {
  padding: 12px 20px;  /* 减小上下内边距 */
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;  /* 减小底部边距 */
}

.header h2 {
  font-size: 18px;  /* 减小标题字体大小 */
  margin: 0;
}

.header h3 {
  font-size: 14px;  /* 减小副标题字体大小 */
  font-weight: normal;
  color: #666;
  margin: 0 0 0 8px;
}

.actions {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 6px 12px;  /* 减小按钮内边距 */
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;  /* 减小按钮文字大小 */
}

.btn.primary {
  background: #4CAF50;
  color: white;
  border: none;
}

.btn.delete {
  background: white;
  color: #dc3545;
  border-color: #dc3545;
}

.btn.delete:hover:not(:disabled) {
  background: #dc3545;
  color: white;
}

.btn.delete:disabled {
  color: #dc3545;
  opacity: 0.6;
}

.content {
  display: grid;
  grid-template-columns: 1fr 5fr 2fr;
  gap: 20px;
  height: calc(100% - 80px);
}

.proposal-figure {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  background-color: white;
  min-height: 200px;
}

.proposal-design {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  background-color: white;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.proposal-result {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  background-color: white;
  min-height: 200px;
}

.collapsible-sections {
  width: 100%;
}

.collapsible-item {
  margin-bottom: 10px;
}

.collapsible-btn {
  width: 100%;
  padding: 15px;
  background: #f5f5f5;
  border: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.collapsible-btn i {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.collapsible-btn.active {
  background: #e0e0e0;
}

.collapsible-content {
  display: none;
  padding: 15px;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
}

.collapsible-content.show {
  display: block;
}

.program-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
}

.term-select-container {
  margin-top: 15px;
}

.term-select-container label {
  display: block;
  margin-bottom: 5px;
  color: #666;
  font-size: 14px;
}

.term-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.panel-size-container {
  margin-bottom: 20px;
}

.panel-size-container label {
  display: block;
  margin-bottom: 5px;
  color: #666;
  font-size: 14px;
}

.panel-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.installation-type-container {
  margin-bottom: 20px;
}

.installation-type-container label {
  display: block;
  margin-bottom: 5px;
  color: #666;
  font-size: 14px;
}

.installation-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

/* 确保SVG响应式缩放 */
.proposal-design svg {
  max-width: 100%;
  height: auto;
}

.add-panel-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4CAF50;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-panel-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.add-panel-btn:not(:disabled):hover {
  background-color: #45a049;
  transform: scale(1.1);
}

.clickable-area {
  cursor: pointer;
  transition: all 0.3s ease;
}

.draggable-panel {
  cursor: move;
  user-select: none;
}

.draggable-panel:hover {
  stroke: #999999;
  stroke-width: 3;
}

.result-item {
  padding: 8px 15px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-item:last-child {
  border-bottom: none;
}

.result-item label {
  font-weight: 500;
  color: #666;
  font-size: 13px;
}

.result-item .value {
  font-size: 15px;
  font-weight: 600;
  color: #333;
}

.result-item .value span {
  margin-left: 2px;
}

.chart-container {
  height: 140px;
  margin-top: 10px;
  padding: 5px;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
}

.chart-title {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  margin-bottom: 5px;
  text-align: center;
}

.chart-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-wrapper canvas {
  max-width: 120px !important;  /* 减小画布最大宽度 */
  max-height: 120px !important;  /* 减小画布最大高度 */
}

.result-item .value.offset {
  color: #4CAF50;
}

.permit-fee {
  background-color: #f8f9fa;
}

.payment {
  background-color: #f8f9fa;
}

.payment label {
  font-size: 12px;  /* 稍微减小付款项的标签字体大小 */
}

.payment .description {
  font-size: 11px;
  color: #666;
  margin-left: 8px;
}

.saved-proposals {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.saved-proposals h4 {
  font-size: 14px;
  color: #666;
  margin: 0 0 10px 0;
}

.saved-proposal-item {
  padding: 8px 12px;
  margin: 5px 0;
  background-color: #f8f9fa;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.saved-proposal-item:hover {
  background-color: #e9ecef;
}

.saved-proposal-item.active {
  background-color: #4CAF50;
  color: white;
}

.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn:disabled:hover {
  background: white;
  transform: none;
}

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 16px;
  min-width: 16px;
  margin-right: 5px;
  cursor: pointer;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 0;
  z-index: 1;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 3px;
  pointer-events: none;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.proposal-name {
  flex: 1;
  padding: 2px 0;
  cursor: pointer;
}

.saved-proposal-item.active .checkbox-container .checkmark {
  border-color: white;
}

.saved-proposal-item.active .checkbox-container input:checked ~ .checkmark {
  background-color: white;
  border-color: white;
}

.saved-proposal-item.active .checkbox-container input:checked ~ .checkmark:after {
  border-color: #4CAF50;
}

.compare-section {
  margin: 15px 0;
  padding: 0 15px;
}

.compare-btn {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.compare-btn:disabled {
  background-color: #cccccc;
}

.compare-btn:not(:disabled):hover {
  background-color: #45a049;
}
</style> 