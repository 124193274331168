<template>
  <div class="active-projects-page">
    <h2>Active Projects</h2>
  </div>
</template>

<script>
export default {
  name: 'ActiveProjectsPage'
}
</script> 