import { defineStore } from 'pinia'
import axiosInstance from '@/plugins/axios'

export const useAuthStore = defineStore('auth', {
  state: () => {
    // Safely parse stored user data
    let storedUser = null
    try {
      const userStr = localStorage.getItem('user')
      if (userStr) {
        storedUser = JSON.parse(userStr)
      }
    } catch (e) {
      console.error('Error parsing stored user:', e)
    }

    return {
      user: storedUser,
      accessToken: localStorage.getItem('accessToken') || null,
      refreshToken: localStorage.getItem('refreshToken') || null,
    }
  },

  actions: {
    setTokens(access, refresh) {
      this.accessToken = access
      this.refreshToken = refresh
      localStorage.setItem('accessToken', access)
      localStorage.setItem('refreshToken', refresh)
    },

    setUser(user) {
      this.user = user
      if (user) {
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        localStorage.removeItem('user')
      }
    },

    async login(email, password) {
      try {
        const response = await axiosInstance.post('token/', {
          email,
          password
        })
        
        this.setTokens(response.data.access, response.data.refresh)
        
        const userResponse = await axiosInstance.get('users/me/', {
          headers: {
            'Authorization': `Bearer ${response.data.access}`
          }
        })
        
        this.setUser(userResponse.data)
        
        // Return the user data for routing decisions
        return userResponse.data
      } catch (error) {
        console.error('Login error:', error.response?.data || error.message)
        throw error
      }
    },

    logout() {
      this.user = null
      this.accessToken = null
      this.refreshToken = null
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    }
  },

  getters: {
    isAuthenticated: (state) => !!state.accessToken,
    currentUser: (state) => state.user
  }
}) 