import axios from 'axios'

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:8000/api/',
  baseURL: process.env.VUE_APP_API_URL + '/api/',
  headers: {
    'Content-Type': 'application/json',
  }
})

// Request interceptor
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      try {
        const refreshToken = localStorage.getItem('refreshToken')
        // const response = await axios.post('http://localhost:8000/api/token/refresh/', {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/api/token/refresh/', {
          refresh: refreshToken
        })
        
        const { access } = response.data
        localStorage.setItem('accessToken', access)
        
        originalRequest.headers.Authorization = `Bearer ${access}`
        return axios(originalRequest)
      } catch (error) {
        // Token refresh failed, redirect to login
        localStorage.clear()
        window.location.href = '/login'
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)

export default axiosInstance 