<template>
  <div class="workplace-container">
    <AppHeader title="Workplace" />
    
    <div class="workplace-content">
      <!-- Sidebar -->
      <div 
        class="sidebar" 
        :class="{ 'sidebar-collapsed': !sidebarOpen }"
        @click.self="toggleSidebar"
      >
        <div class="sidebar-groups">
          <!-- CRM Group -->
          <div class="sidebar-group">
            <div class="group-header" @click="toggleGroup('crm')" :title="!sidebarOpen ? 'CRM' : ''">
              <i class="fas fa-users"></i>
              <span class="header-text">CRM</span>
              <i :class="['fas', 'fa-chevron-down', 'arrow-icon', { 'rotated': openGroups.crm }]"></i>
            </div>
            <div class="group-items" v-show="openGroups.crm">
              <div class="sidebar-item" @click="navigateTo('crm/leads')" :title="!sidebarOpen ? 'Leads' : ''">
                <i class="fas fa-user-plus"></i>
                <span class="item-text">Leads</span>
              </div>
              <div class="sidebar-item" @click="navigateTo('crm/proposals')" :title="!sidebarOpen ? 'Proposals' : ''">
                <i class="fas fa-file-signature"></i>
                <span class="item-text">Proposals</span>
              </div>
              <div class="sidebar-item" @click="navigateTo('crm/present-proposal')" :title="!sidebarOpen ? 'Present Proposal' : ''">
                <i class="fas fa-tv"></i>
                <span class="item-text">Present Proposal</span>
              </div>
              <div class="sidebar-item" @click="navigateTo('crm/deals')" :title="!sidebarOpen ? 'Deals' : ''">
                <i class="fas fa-handshake"></i>
                <span class="item-text">Deals</span>
              </div>
              <div class="sidebar-item" @click="navigateTo('crm/meeting')" :title="!sidebarOpen ? 'Meeting' : ''">
                <i class="fas fa-calendar-alt"></i>
                <span class="item-text">Meeting</span>
              </div>
            </div>
          </div>

          <!-- Logistics Group (原 Inventory Group) -->
          <div class="sidebar-group">
            <div class="group-header" @click="toggleGroup('logistics')" :title="!sidebarOpen ? 'Logistics' : ''">
              <i class="fas fa-truck-loading"></i>
              <span class="header-text">Logistics</span>
              <i :class="['fas', 'fa-chevron-down', 'arrow-icon', { 'rotated': openGroups.logistics }]"></i>
            </div>
            <div class="group-items" v-show="openGroups.logistics">
              <div class="sidebar-item" @click="navigateTo('logistics/stock')" :title="!sidebarOpen ? 'Stock' : ''">
                <i class="fas fa-boxes"></i>
                <span class="item-text">Stock</span>
              </div>
              <div class="sidebar-item" @click="navigateTo('logistics/orders')" :title="!sidebarOpen ? 'Orders' : ''">
                <i class="fas fa-truck"></i>
                <span class="item-text">Orders</span>
              </div>
            </div>
          </div>

          <!-- Template Group -->
          <div class="sidebar-group">
            <div class="group-header" @click="toggleGroup('template')" :title="!sidebarOpen ? 'Template' : ''">
              <i class="fas fa-file-alt"></i>
              <span class="header-text">Template</span>
              <i :class="['fas', 'fa-chevron-down', 'arrow-icon', { 'rotated': openGroups.template }]"></i>
            </div>
            <div class="group-items" v-show="openGroups.template">
              <div class="sidebar-item" @click="showNewTemplate" :title="!sidebarOpen ? 'Create' : ''">
                <i class="fas fa-plus-square"></i>
                <span class="item-text">Create</span>
              </div>
              <div class="sidebar-item" @click="navigateTo('template/contracts')" :title="!sidebarOpen ? 'Contracts' : ''">
                <i class="fas fa-file-contract"></i>
                <span class="item-text">Contracts</span>
              </div>
              <div class="sidebar-item" @click="navigateTo('template/invoices')" :title="!sidebarOpen ? 'Invoices' : ''">
                <i class="fas fa-file-invoice"></i>
                <span class="item-text">Invoices</span>
              </div>
            </div>
          </div>

          <!-- Projects Group -->
          <div class="sidebar-group">
            <div class="group-header" @click="toggleGroup('projects')" :title="!sidebarOpen ? 'Projects' : ''">
              <i class="fas fa-project-diagram"></i>
              <span class="header-text">Projects</span>
              <i :class="['fas', 'fa-chevron-down', 'arrow-icon', { 'rotated': openGroups.projects }]"></i>
            </div>
            <div class="group-items" v-show="openGroups.projects">
              <div class="sidebar-item" @click="navigateTo('projects/active')" :title="!sidebarOpen ? 'Active' : ''">
                <i class="fas fa-tasks"></i>
                <span class="item-text">Active</span>
              </div>
              <div class="sidebar-item" @click="navigateTo('projects/history')" :title="!sidebarOpen ? 'History' : ''">
                <i class="fas fa-history"></i>
                <span class="item-text">History</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Main Content Area -->
      <div class="main-area" :class="{ 'expanded': !sidebarOpen }">
        <NewTemplate 
          v-if="currentView === 'newTemplate'"
          @save="handleTemplateSave"
          @cancel="currentView = null"
          class="full-area"
        />
        <router-view v-else></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import AppHeader from '@/components/AppHeader.vue'
import NewTemplate from '@/components/NewTemplate.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'WorkplacePage',
  components: {
    AppHeader,
    NewTemplate
  },
  setup() {
    const sidebarOpen = ref(true)
    const currentView = ref(null)
    const openGroups = reactive({
      crm: false,
      logistics: false,
      template: false,
      projects: false
    })

    const router = useRouter()

    const toggleSidebar = (event) => {
      if (event.target === event.currentTarget) {
        sidebarOpen.value = !sidebarOpen.value
      }
    }

    const toggleGroup = (group) => {
      Object.keys(openGroups).forEach(key => {
        if (key !== group) openGroups[key] = false
      })
      openGroups[group] = !openGroups[group]
    }

    const showNewTemplate = () => {
      currentView.value = 'newTemplate'
    }

    const handleTemplateSave = (template) => {
      console.log('Saving template:', template)
      // Here you would typically save the template to your backend
      currentView.value = null
    }

    const navigateTo = (path) => {
      currentView.value = null
      router.push(`/workplace/${path}`)
    }

    return {
      sidebarOpen,
      openGroups,
      toggleSidebar,
      toggleGroup,
      currentView,
      showNewTemplate,
      handleTemplateSave,
      navigateTo
    }
  }
}
</script>

<style scoped>
.workplace-container {
  min-height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.workplace-content {
  flex: 1;
  display: flex;
  position: relative;
}

.sidebar {
  width: 250px;
  background-color: white;
  border-right: 1px solid #ddd;
  transition: all 0.3s;
  overflow-x: hidden;
  position: relative;
  height: calc(100vh - 48px);
  cursor: pointer;
}

.sidebar-collapsed {
  width: 50px;
}

.sidebar-groups {
  padding: 1rem 0;
}

.group-header {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.group-header:hover {
  background-color: #f5f5f5;
}

.group-items {
  background-color: #f8f9fa;
  overflow: hidden;
  transition: all 0.3s;
}

.sidebar-item {
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;
}

.sidebar-item:hover {
  background-color: #f0f0f0;
}

.sidebar-collapsed .group-header {
  padding: 0.75rem;
  justify-content: center;
}

.sidebar-collapsed .sidebar-item {
  padding: 0.75rem;
  justify-content: center;
}

.sidebar-collapsed .header-text,
.sidebar-collapsed .item-text,
.sidebar-collapsed .arrow-icon {
  display: none;
}

.group-header i:first-child,
.sidebar-item i {
  width: 20px;
  text-align: center;
  margin-right: 0.5rem;
  font-size: 1rem;
  flex-shrink: 0;
  opacity: 1;
}

.sidebar-collapsed .group-header i:first-child,
.sidebar-collapsed .sidebar-item i {
  margin: 0;
  width: auto;
}

.arrow-icon {
  margin-left: auto;
  transition: transform 0.3s;
}

.arrow-icon.rotated {
  transform: rotate(180deg);
}

.group-items {
  transition: all 0.3s ease-in-out;
}

.main-area {
  flex: 1;
  padding: 2px;
  transition: margin-left 0.3s;
  overflow: hidden;
}

.full-area {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.main-area.expanded {
  margin-left: 0;
}

[title] {
  position: relative;
}

.sidebar-collapsed .sidebar-item,
.sidebar-collapsed .group-header {
  overflow: visible;
}

@media (max-width: 768px) {
  .main-area.expanded {
    margin-left: -150px;
  }
}

/* 添加一些路由视图的样式 */
.router-view-container {
  height: 100%;
  width: 100%;
  overflow: auto;
}
</style> 