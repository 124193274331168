<template>
  <div class="leads-page">
    <h2>Leads Management</h2>
  </div>
</template>

<script>
export default {
  name: 'LeadsPage'
}
</script> 