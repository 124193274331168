<template>
  <div class="orders-page">
    <h2>Orders Management</h2>
  </div>
</template>

<script>
export default {
  name: 'OrdersPage'
}
</script> 