<template>
  <div class="meeting-page">
    <h2>Meeting Management</h2>
  </div>
</template>

<script>
export default {
  name: 'MeetingPage'
}
</script> 