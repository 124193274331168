<template>
  <div class="new-template">
    <div class="template-header">
      <div class="template-info">
        <input 
          type="text" 
          v-model="templateName" 
          placeholder="Template name"
          class="template-name-input"
        />
        <select 
          v-model="templateGroup" 
          class="template-group-select"
          :class="{ 'placeholder': !templateGroup }"
        >
          <option value="" disabled selected>Select template group</option>
          <option value="proposal">Proposal</option>
          <option value="contract">Contract</option>
          <option value="invoice">Invoice</option>
        </select>
        <button class="save-btn" @click="saveTemplate">
          <i class="fas fa-save"></i>
          Save
        </button>
      </div>
    </div>

    <div class="template-content">
      <!-- 左侧工具栏 -->
      <div class="toolbar">
        <button class="tool-btn new-page">New Page</button>
        <div class="tool-group">
          <select class="tool-select" v-model="pageOrientation" title="Page Orientation">
            <option value="portrait">Portrait</option>
            <option value="landscape">Landscape</option>
          </select>
          <select class="tool-select" v-model="pageSize" title="Page Size">
            <option value="letter">Letter</option>
            <option value="a4">A4</option>
            <option value="a5">A5</option>
            <option value="b5">B5</option>
            <option value="legal">Legal</option>
          </select>
        </div>
        <div class="tool-divider"></div>
        <div class="tool-group">
          <div class="tool-row">
            <button class="tool-btn" title="Text">
              <i class="fas fa-font"></i>
            </button>
            <button class="tool-btn" title="Image">
              <i class="fas fa-image"></i>
            </button>
          </div>
          <div class="tool-row">
            <button class="tool-btn" title="Chart">
              <i class="fas fa-chart-bar"></i>
            </button>
            <button class="tool-btn" title="Table">
              <i class="fas fa-table"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="editor-area">
        <div class="page-container">
          <div class="edit-page"></div>
        </div>
        <div class="page-preview">
          <div class="preview-page"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'NewTemplate',
  setup() {
    const templateName = ref('')
    const templateGroup = ref('')
    const pageOrientation = ref('portrait')
    const pageSize = ref('a4')

    return {
      templateName,
      templateGroup,
      pageOrientation,
      pageSize
    }
  }
}
</script>

<style scoped>
.new-template {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
}

.template-header {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.template-info {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.template-name-input {
  width: 400px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
}

.template-group-select {
  width: 150px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
}

.template-group-select.placeholder {
  color: #757575; /* 未选择时的文字颜色 */
}

.template-group-select option[value=""] {
  color: #757575;
}

.template-group-select option:not([value=""]) {
  color: #333;
}

.template-content {
  flex: 1;
  display: flex;
  height: calc(100vh - 80px); /* 减去header高度 */
}

/* 左侧工具栏样式 */
.toolbar {
  width: 120px;
  background-color: white;
  border-right: 1px solid #ddd;
  padding: 1rem 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.tool-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.tool-btn:hover {
  background-color: #f5f5f5;
}

.new-page {
  background-color: #4285f4;
  color: white;
  border: none;
  width: 90px;
  height: auto;
  padding: 0.5rem;
}

.new-page:hover {
  background-color: #3367d6;
}

.tool-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
}

.tool-divider {
  width: 80%;
  height: 1px;
  background-color: #ddd;
  margin: 0.5rem 0;
}

.tool-row {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  width: 100%;
}

.editor-area {
  flex: 1;
  display: flex;
  padding: 2rem;
  gap: 2rem;
  background-color: #f5f5f5;
  min-width: 0;
  overflow: auto;
}

.page-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.edit-page {
  width: 595px;
  height: 842px;
  background: white;
  border: 2px dashed #ddd;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.page-preview {
  width: 200px;
  display: flex;
  justify-content: center;
}

.preview-page {
  width: 150px;
  height: 212px;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.tool-select {
  width: 90px;
  height: 40px;
  padding: 0 0.5rem;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}

.tool-select:hover {
  background-color: #f5f5f5;
}

.tool-select:focus {
  outline: none;
  border-color: #4285f4;
}

.tool-group .tool-select {
  margin-bottom: 0.5rem;
}

.save-btn {
  margin-left: auto;
  padding: 0.5rem 1rem;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.save-btn:hover {
  background-color: #3367d6;
}

.save-btn i {
  font-size: 1rem;
}
</style> 