import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import Workplace from '@/views/Workplace.vue'
import Leads from '@/views/crm/Leads.vue'
import Proposals from '@/views/crm/Proposals.vue'
import Deals from '@/views/crm/Deals.vue'
import Meeting from '@/views/crm/Meeting.vue'
import Stock from '@/views/logistics/Stock.vue'
import Orders from '@/views/logistics/Orders.vue'
import Contracts from '@/views/template/Contracts.vue'
import Invoices from '@/views/template/Invoices.vue'
import Active from '@/views/projects/Active.vue'
import History from '@/views/projects/History.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: { requiresAuth: true, requiresStaff: true }
  },
  {
    path: '/customer',
    name: 'Customer',
    component: () => import('@/views/Customer.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workplace',
    name: 'Workplace',
    component: Workplace,
    children: [
      {
        path: 'crm/leads',
        name: 'Leads',
        component: Leads
      },
      {
        path: 'crm/proposals',
        name: 'Proposals',
        component: Proposals
      },
      {
        path: 'crm/deals',
        name: 'Deals',
        component: Deals
      },
      {
        path: 'crm/meeting',
        name: 'Meeting',
        component: Meeting
      },
      {
        path: 'logistics/stock',
        name: 'Stock',
        component: Stock
      },
      {
        path: 'logistics/orders',
        name: 'Orders',
        component: Orders
      },
      {
        path: 'template/contracts',
        name: 'Contracts',
        component: Contracts
      },
      {
        path: 'template/invoices',
        name: 'Invoices',
        component: Invoices
      },
      {
        path: 'projects/active',
        name: 'ActiveProjects',
        component: Active
      },
      {
        path: 'projects/history',
        name: 'ProjectHistory',
        component: History
      }
    ]
  },
  {
    path: '/',
    redirect: '/login'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  const isAuthenticated = authStore.isAuthenticated
  const isStaff = authStore.currentUser?.is_staff

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login')
    return
  }

  if (to.meta.requiresStaff && !isStaff) {
    next('/customer')
    return
  }

  if (to.path === '/login' && isAuthenticated) {
    next(isStaff ? '/dashboard' : '/customer')
    return
  }

  next()
})

export default router 