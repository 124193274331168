<template>
  <header class="app-header">
    <h1>{{ title }}</h1>
    <div class="user-controls">
      <div class="welcome-text">
        Welcome back, <span class="username">{{ user?.username }}</span>
      </div>
      <button @click="handleLogout" class="logout-btn">
        <i class="fas fa-sign-out-alt"></i>
        Logout
      </button>
    </div>
  </header>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'AppHeader',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const user = computed(() => authStore.currentUser)

    const handleLogout = () => {
      authStore.logout()
      router.push('/login')
    }

    return {
      user,
      handleLogout
    }
  }
}
</script>

<style scoped>
.app-header {
  background-color: #fff;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  height: 48px;
}

.app-header h1 {
  font-size: 1.25rem;
  margin: 0;
}

.user-controls {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.welcome-text {
  color: #666;
  font-size: 0.95rem;
}

.username {
  font-weight: 600;
  color: #333;
}

.logout-btn {
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.logout-btn:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  .welcome-text {
    display: none;
  }
  
  .app-header {
    padding: 0.5rem 1rem;
  }
}
</style> 